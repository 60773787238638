import Head from "next/head";
import { FC } from "react";

import { useUserContext } from "~/contexts/UserContext";

import FocusLayout from "../FocusLayout";
import FocusBody from "../FocusLayout/FocusBody";
import FocusHeader from "../FocusLayout/FocusHeader";
import { Breadcrumb } from "../FocusLayout/FocusHeader/types";

export const AuthorizationErrorLayout: FC<{
  breadcrumbs?: Breadcrumb[];
}> = ({ breadcrumbs, children }) => {
  const { authenticated, user } = useUserContext();

  return (
    <>
      <Head>
        <title>Private Area</title>
      </Head>
      <FocusLayout>
        <FocusHeader
          breadcrumbs={breadcrumbs ?? []}
          actionButton={() => null}
        />
        <FocusBody>
          <h1>Whoops! You&apos;ve wandered into a private area.</h1>
          {authenticated && (
            <p>
              <em>
                Click <a href={`/${user.workspaces?.[0]?._id}`}>here</a> to go
                back to your team&apos;s dashboard.
              </em>
            </p>
          )}
          {children && (
            <div>
              If you feel you&apos;ve reached this page in error, please contact
              support and share the message below:
              <pre
                css={({ theme }) => ({
                  backgroundColor: theme.colors.white,
                  padding: "1rem",
                  border: `${theme.colors.cinnabar} 1px solid`,
                })}
              >
                <code>{children}</code>
              </pre>
            </div>
          )}
        </FocusBody>
      </FocusLayout>
    </>
  );
};
