import { createRef, FC, useCallback, useState } from "react";
import styled, { css } from "styled-components";

import ListIcon from "~/assets/icons/list.svg";
import Button from "~/components/Button";
import { CollapsibleHeader } from "~/components/CollapsibleHeader";
import { Column } from "~/components/Column";
import { ResponsiveHide } from "~/components/Hidden/ResponsiveHide";
import { IntegrationsRequiringReinstall } from "~/components/IntegrationsRequiringReinstall";
import Sidebar from "~/components/Sidebar";
import Tooltip from "~/components/Tooltip";
import WaitRoom from "~/containers/Common/WaitRoom";
import { useUserContext } from "~/contexts/UserContext";
import {
  useWorkspaceContext,
  WorkspaceProvider,
} from "~/contexts/WorkspaceContext";
import useInterval from "~/hooks/useInterval";
import useLocalStorage from "~/hooks/useLocalStorage";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";
import { breakpoints } from "~/styles/global";

import { SidebarWidthRem } from "../../../components/Sidebar/constants";
import { AuthorizationErrorLayout } from "../ErrorLayout/AuthorizationErrorLayout";
import { UnexpectedErrorLayout } from "../ErrorLayout/UnexpectedErrorLayout";

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.moonLight};
  height: 100vh;
  @media (min-width: ${breakpoints.sm.min}) {
    margin-top: 0;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
  padding: 0;
  padding-bottom: 100px; /* magic number based on intercom button */
  @media (max-width: ${breakpoints.sm.max}) {
    margin-top: 4rem;
  }
`;

const ToggleSidebarIcon = styled(ListIcon)`
  border-radius: 0.25rem;
  padding: 0.25rem;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.moonDark};
`;

const SidebarLayoutInner: FC<{ className?: string }> = ({
  children,
  className,
}) => {
  const { slug, workspace, isMember, isGuest, state } = useWorkspaceContext();
  const { authenticated } = useUserContext();
  const [sidebarOpen, setSidebarOpen] = useLocalStorage("sidebarOpen", true);
  const toggleSidebar = useCallback(() => setSidebarOpen((prev) => !prev), [
    setSidebarOpen,
  ]);
  const billingBannerRef = createRef<HTMLDivElement>();
  const [billingBannerHeight, setBillingBannerHeight] = useState(0);
  const updateBillingBannerHeight = useCallback(() => {
    setBillingBannerHeight(billingBannerRef.current?.clientHeight || 0);
  }, [billingBannerRef, setBillingBannerHeight]);

  // This is a hack to figure out whether or not the billing banner is visible
  // and thus whether we should adjust the vertical offset of the menu toggle.
  // Right now, the logic for whether or not the banner is visible is buried in
  // the BillingStatusBanner component, so until that is refactored, we need to
  // use this hack.
  useInterval(() => {
    updateBillingBannerHeight();
  }, 100);

  if (!workspace && authenticated) {
    if (state === "IDLE" || state === "LOADING") return <WaitRoom />;
    if (isMember || isGuest) {
      return (
        <Container>
          <CollapsibleHeader />
          <Content>
            <UnexpectedErrorLayout errorMessage="We aren't able to load data for this workspace. Please try again later." />
          </Content>
        </Container>
      );
    }

    return <AuthorizationErrorLayout />;
  }

  return (
    <>
      <IntegrationsRequiringReinstall />
      <Container
        css={`
          width: 100vw;
          @media (min-width: ${breakpoints.sm.min})  {
            padding-left: ${sidebarOpen ? `${SidebarWidthRem}rem` : "0rem"};
            @media (prefers-reduced-motion: no-preference) {
              padding-left 0.2s ease;
            }
          }
          
        `}
        className={className}
      >
        <ResponsiveHide above="sm">
          <CollapsibleHeader />
        </ResponsiveHide>
        <ResponsiveHide below="sm">
          <Sidebar
            workspaceLogoFileId={workspace?.logoFileId}
            workspaceName={workspace?.name || ""}
            workspaceSlug={slug}
            open={sidebarOpen}
          />
          <Column
            css={`
              position: fixed;
              z-index: 1;
              height: ${billingBannerHeight}px;
              width: ${sidebarOpen
                ? `calc(100% - ${SidebarWidthRem}rem)`
                : "100%"};
            `}
          >
            <Tooltip
              title={sidebarOpen ? "Hide menu" : "Show menu"}
              enterDelay={100}
              leaveDelay={300}
            >
              <Button
                variant="icon"
                onClick={toggleSidebar}
                css={css`
                  margin-top: 1.125rem;
                  transition: transform 0.1s ease-in-out;
                  left: ${sidebarOpen ? 16.5 : 0.5}rem;
                  transform: rotate(${sidebarOpen ? "90deg" : "0deg"});
                  &:hover {
                    background-color: ${({ theme }) => theme.colors.moon25};
                  }
                `}
              >
                <ToggleSidebarIcon aria-hidden />
              </Button>
            </Tooltip>
          </Column>
        </ResponsiveHide>
        <Content
          css={`
            padding-top: ${billingBannerHeight}px;
            overflow-x: hidden;
            overflow-y: auto;
          `}
        >
          {children}
        </Content>
      </Container>
    </>
  );
};

const SidebarLayout: FC<{ className?: string }> = ({
  className = "",
  children,
}) => {
  const slug = useWorkspaceSlug();
  return (
    <WorkspaceProvider slug={slug}>
      <SidebarLayoutInner className={className}>{children}</SidebarLayoutInner>
    </WorkspaceProvider>
  );
};

export default SidebarLayout;
