import { useRouter } from "next/router";
import type { FC } from "react";
import { css } from "styled-components";

import { BurntToaster } from "~/browser/shared/components/BurnToaster/BurntToaster";
import Header from "~/components/Header";
import { ResponsiveHide } from "~/components/Hidden/ResponsiveHide";
import SidebarLayout from "~/containers/Layouts/SidebarLayout";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";
import { breakpoints } from "~/styles/global";

import { Content, ContentBody, ContentHeader } from "./styles";

const MainLayout: FC<{ showSidebar?: boolean }> = ({
  children,
  showSidebar = true,
  ...rest
}) => {
  const slug = useWorkspaceSlug();
  const { pathname } = useRouter();

  if (!showSidebar) {
    return (
      <>
        <BurntToaster />
        <Content>
          <ResponsiveHide below="sm">
            <ContentHeader
              $variant={
                pathname.includes("/explore") ? "paper-malibu" : "transparent"
              }
            >
              <Header showLogo={true} workspaceSlug={slug} />
            </ContentHeader>
          </ResponsiveHide>
          <ContentBody {...rest}>{children}</ContentBody>
        </Content>
      </>
    );
  }

  return (
    <>
      <SidebarLayout>
        <BurntToaster />
        <Content>
          <ResponsiveHide below="sm">
            <ContentHeader
              $variant={
                pathname.includes("/explore") ? "paper-malibu" : "transparent"
              }
            >
              <Header workspaceSlug={slug} />
            </ContentHeader>
          </ResponsiveHide>
          <ContentBody
            css={css`
              ${pathname.includes("/explore")
                ? ""
                : css`
                    padding-top: 1rem;
                    padding-right: 0.5rem;
                    padding-left: 0.5rem;
                    margin-top: 1rem;
                    @media (min-width: ${breakpoints.sm.min}) {
                      padding-right: 1rem;
                      padding-left: 1rem;
                    }
                  `};
            `}
            {...rest}
          >
            {children}
          </ContentBody>
        </Content>
      </SidebarLayout>
    </>
  );
};

export default MainLayout;
