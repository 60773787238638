import { Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";

import DefaultCheckmarkIcon from "~/assets/icons/checkmark-thinner.svg";
import CloseIcon from "~/assets/icons/x.svg";
import { LS_TOASTER_MESSAGE_KEY } from "~/browser/shared/components/BurnToaster/constants";
import { ToastMessage } from "~/browser/shared/components/BurnToaster/types";
import Button from "~/components/Button";

const CheckmarkIcon = styled(DefaultCheckmarkIcon)`
  height: 1.5rem;
  width: 1.5rem;
`;

/**
 * The BurntToaster component is a global Snackbar that leverages local storage for latent
 * messaging. An example of this would be if we delete a page and push the user to a
 * new URL and we want to notify the user.
 *
 * You can use the saveToastMessage method to store the message object so that this
 * component can use it.
 */
export const BurntToaster = () => {
  const msgStr = window.localStorage.getItem(LS_TOASTER_MESSAGE_KEY);
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [msg, setMsg] = useState<ToastMessage | undefined>();

  const handleClose = useCallback((_, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  }, []);

  useEffect(() => {
    if (msgStr) {
      setMsg(JSON.parse(msgStr));
      window.localStorage.removeItem(LS_TOASTER_MESSAGE_KEY);
    }
  }, [msgStr]);

  if (!msg) {
    return null;
  }

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionProps={{
        appear: false,
      }}
      autoHideDuration={6000}
    >
      <div
        css={{
          alignItems: "center",
          backgroundColor:
            msg.variant === "Primary"
              ? theme.colors.speech
              : theme.colors.white,
          borderRadius: 4,
          color:
            msg.variant === "Primary"
              ? theme.colors.white
              : theme.colors.speech,
          display: "flex",
          gap: "1rem",
          padding: "1rem",
        }}
      >
        <CheckmarkIcon /> {msg.text}
        <Button variant="icon" onClick={handleClose}>
          <CloseIcon />
        </Button>
      </div>
    </Snackbar>
  );
};
