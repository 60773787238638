import { ApolloError } from "@apollo/client";
import { FC } from "react";

import FocusBody from "~/containers/Layouts/FocusLayout/FocusBody";
import { useUserContext } from "~/contexts/UserContext";
import useWorkspaceSlug from "~/hooks/useWorkspaceSlug";
import { captureException } from "~/utils/captureMessage";
import { hasAuthorizationError } from "~/utils/Errors";

export enum ErrorActions {
  GoToDashboard,
  MessageSupport,
}

export function formatApolloError(error: ApolloError) {
  return process.env.NEXT_PUBLIC_IS_PRODUCTION
    ? error.message
    : JSON.stringify(error, null, 2);
}

const AllowedActions: FC<{
  allowedActions: ErrorActions[];
  workspaceSlug: string;
  errorMessage: string;
}> = ({ allowedActions, workspaceSlug }) => (
  <>
    {allowedActions.map((action, idx) => {
      let component;
      switch (action) {
        case ErrorActions.GoToDashboard:
          component = (
            <p>
              <a href={`/${workspaceSlug}`}>Click here</a> to go back to your
              team&apos;s dashboard.
            </p>
          );
          break;
        case ErrorActions.MessageSupport:
          component = <p>Whoops! Looks like something went wrong.</p>;
          break;
      }

      return (
        <div key={idx}>
          {idx ? <p>Or…</p> : null}
          {component}
        </div>
      );
    })}
  </>
);

const ErrorDisplay: FC<{
  errorMessage: string;
  actions?: Array<ErrorActions>;
  error?: Error;
}> = ({
  error,
  errorMessage,
  actions = [ErrorActions.GoToDashboard, ErrorActions.MessageSupport],
}) => {
  const { authenticated } = useUserContext();
  const workspaceSlug = useWorkspaceSlug();

  const allowedActions = actions.filter(
    (action) => action !== ErrorActions.GoToDashboard || authenticated
  );

  if (error) {
    captureException(error);
  }

  const notAuthorized =
    error instanceof ApolloError && hasAuthorizationError({ error });

  if (notAuthorized) {
    return (
      <FocusBody>
        <h1>Whoops! Looks like you are not authorized to view this content.</h1>

        <AllowedActions
          allowedActions={allowedActions}
          workspaceSlug={workspaceSlug}
          errorMessage={errorMessage}
        />
      </FocusBody>
    );
  }

  return (
    <FocusBody>
      <h1>Whoops! Something went wrong.</h1>
      {errorMessage && (
        <>
          <pre
            css={({ theme }) => ({
              backgroundColor: theme.colors.white,
              padding: "1rem",
              border: `${theme.colors.cinnabar} 1px solid`,
            })}
          >
            <code>{errorMessage}</code>
          </pre>

          <AllowedActions
            allowedActions={allowedActions}
            workspaceSlug={workspaceSlug}
            errorMessage={errorMessage}
          />
        </>
      )}
    </FocusBody>
  );
};

export default ErrorDisplay;
