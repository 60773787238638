import Head from "next/head";
import { FC } from "react";

import ErrorDisplay from "~/containers/Common/Error/ErrorDisplay";

import FocusLayout from "../FocusLayout";
import FocusHeader from "../FocusLayout/FocusHeader";
import { Breadcrumb } from "../FocusLayout/FocusHeader/types";

export const UnexpectedErrorLayout: FC<{
  breadcrumbs?: Breadcrumb[];
  error?: Error;
  errorMessage?: string;
}> = ({ breadcrumbs, error, errorMessage }) => {
  return (
    <>
      <Head>
        <title>Unexpected Error</title>
      </Head>
      <FocusLayout>
        <FocusHeader breadcrumbs={breadcrumbs ?? []} />
        <ErrorDisplay
          error={error}
          errorMessage={
            errorMessage || error?.message || "Sorry, something went wrong"
          }
        />
      </FocusLayout>
    </>
  );
};
