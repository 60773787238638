import styled, { css } from "styled-components";

import BackgroundPaperMalibu from "~/assets/images/background-paper-malibu.png";

export const Content = styled.div`
  flex-grow: 1;
`;

export const ContentHeader = styled.div<{
  $variant?: "paper-malibu" | "transparent";
}>`
  display: flex;
  justify-content: flex-end;
  padding: 1.0625rem;
  padding-right: 1.125rem;
  ${({ $variant }) =>
    ({
      "paper-malibu": css`
        background-color: ${({ theme }) => theme.colors.malibu};
        background-image: url("${BackgroundPaperMalibu.src}");
        background-repeat: no-repeat;
        background-size: cover;
      `,
      transparent: css`
        background-color: transparent;
      `,
    }[$variant || "transparent"])}
`;

export const ContentBody = styled.div``;
