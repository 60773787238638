import { useWorkspaceContext } from "~/contexts/WorkspaceContext";
import { Group } from "~/model/group";

export const useSlackConnectURI = () => {
  const { workspace } = useWorkspaceContext();

  if (!workspace) return null;

  const redirect_uri_param = `redirect_uri=${encodeURIComponent(
    `https://${window.location.hostname}/api/connect/slack`
  )}`;

  const workspace_param = `state=${encodeURIComponent(
    JSON.stringify({ workspace: workspace._id })
  )}`;

  const slackConnectURI = `https://slack.com/oauth/v2/authorize?client_id=${process.env.NEXT_PUBLIC_SLACK_CLIENT_ID}&scope=chat:write,chat:write.public,im:write,users:read,users:read.email&user_scope=openid&${redirect_uri_param}&${workspace_param}`;

  return slackConnectURI;
};

export const useDiscordConnectURI = ({ group }: { group: Group }) => {
  const params = `state=${encodeURIComponent(
    JSON.stringify({ workspace: group.workspace._id, group: group._id })
  )}`;

  const redirect_uri_param = `redirect_uri=${encodeURIComponent(
    `https://${window.location.hostname}/api/connect/discord`
  )}`;
  const discordBotConnectURI = `https://discord.com/api/oauth2/authorize?client_id=${process.env.NEXT_PUBLIC_DISCORD_CLIENT_ID}&permissions=2048&${redirect_uri_param}&response_type=code&scope=bot%20identify&${params}`;

  return discordBotConnectURI;
};
