import React from "react";
import { useTheme } from "styled-components";

import { useWorkspaceContext } from "~/contexts/WorkspaceContext";
import { useSlackConnectURI } from "~/hooks/connectURI";

export function IntegrationsRequiringReinstall() {
  const { workspace } = useWorkspaceContext();
  const theme = useTheme();

  const slackConnectURI = useSlackConnectURI();
  if (!slackConnectURI || !workspace?.integrationsRequiringReinstall?.length) {
    return null;
  }

  return (
    <div
      css={{
        backgroundColor: theme.colors.malibu,
        padding: "1rem",
        textAlign: "center",
        width: "100%",
      }}
    >
      <span css={{ marginRight: ".25rem" }} aria-hidden="true">
        👋{" "}
      </span>{" "}
      We&apos;ve made improvements to our integrations! Please{" "}
      {workspace.integrationsRequiringReinstall.map((integration) => (
        <>
          <a
            css={{
              color: theme.colors.oil,
              textDecoration: "underline",
              fontWeight: theme.weights.medium,
            }}
            href={slackConnectURI}
          >
            reconnect {integration}
          </a>{" "}
          to see the update.
        </>
      ))}
    </div>
  );
}
